import App from 'next/app'
import React from 'react'

import 'antd/dist/antd.css';
import '../src/styles/scss/app.scss';
import '../src/styles/css/app.css';
import 'antd/lib/mention/style/index.css';
import "react-image-crop/dist/ReactCrop.css";

import withReduxStore from '../src/utils/withredux'
import { Provider } from 'react-redux'
import Layout from "../src/components/layouts";
import NProgress from 'nprogress';
import Router from 'next/router';
import { isAuthenticated } from "../src/utils/auth";
import { authenticatedUser } from "../src/store/actions";
import ReactGA from 'react-ga'

import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: 'https://c4773c9b28ee4b49b2ef98cf34261ed8@sentry.io/2793724'
});

Router.onRouteChangeStart = (url) => {
    NProgress.start();
};

Router.onRouteChangeComplete = () => {
    ReactGA.initialize('UA-156529860-2');
    ReactGA.pageview(window.location.pathname);
    return NProgress.done()
};
Router.onRouteChangeError = () => NProgress.done();
class MyApp extends App {
    state = {
        socket: null
    }

    constructor() {
        super();
    }

    static async getInitialProps({ Component, ctx }) {
        const pageProps = {};
        pageProps.isAuthenticated = false;
        try {
            let propsFromComponents = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
            if (isAuthenticated(ctx)) {
                if (propsFromComponents.isAuthenticated !== true) {
                    await ctx.store.dispatch(authenticatedUser(ctx));
                    pageProps.isAuthenticated = true;
                }
            }

            // let propsFromComponents = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

            return { ...pageProps, ...propsFromComponents };

        } catch (e) {
        }
    }

    render() {
        const { Component, pageProps, reduxStore } = this.props;
        return (
            <Provider store={reduxStore}>
                <Layout {...this.props}>
                    <Component {...pageProps} {...this.props} />
                </Layout>
            </Provider>
        )
    }

    componentDidMount() {
        ReactGA.initialize('UA-156529860-2');
        ReactGA.pageview(window.location.pathname);
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.APP_ENV !== 'DEV') {
            Sentry.withScope((scope) => {
                Object.keys(errorInfo).forEach((key) => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });

            super.componentDidCatch(error, errorInfo);
        }
    }

}

export default withReduxStore(MyApp)