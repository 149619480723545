import React, { Component } from 'react';
import Head from 'next/head';
import { AddToFavourite, AppFooter, AppNav, QuickSearch } from './partials';
import AuthModel from "./partials/AuthModel";
import { connect } from "react-redux";
import ApplyModal from "./partials/ApplyModal";
import {
    generateStateUpdate,
    jobFilterInputChange,
    feedbackGenerateState
} from "../../store/actions";
import { APP_GENERAL_STATE } from "../../store/ActionTypes";
import { Feedback } from "../common/FeedBack";

class Layout extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { showFooter, footerExtraPadding } = this.props;
        let { authCheck, ica } = this.props.reduxStore.getState().user;
        if (authCheck === true && ica === true) {
            authCheck = false;
        }
        return (<React.Fragment>
            <Head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="author" content="rojgarisanjal.com" />
                <meta name="theme-color" content="#00b365 " />
                <meta name="google-site-verification" content="H68s4dU1JeGmW4Ht1sxIfBEES6yImgq-V2HZGEi_usg" />

                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
                    integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
                    crossOrigin="anonymous" />
                <link href={'https://rojal.s3.ap-south-1.amazonaws.com/rojal/favicon-96x96.png'} rel={'icon'} />

                <title>{this.props.title || 'Welcome - Rojgari Sanjal'}</title>
                <meta name="description"
                    content={this.props.meta_desc ? this.props.meta_desc : "Rojgari sanjal offers the widest range of jobs available in Nepal. Search your field of interest and who knows the job of your dreams might be just a click away!"} />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1458099779120656"
                    crossorigin="anonymous"></script>
                <meta name="robots" content="index, follow" />
                <meta property="og:url" content={"https://www.rojgarisanjal.com" + this.props.router.asPath} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={this.props.title || 'Welcome - Rojgari Sanjal'} />
                <meta property="og:description"
                    content={this.props.meta_desc ? this.props.meta_desc : this.props.title + " | Rojgari sanjal offers the widest range of jobs available in Nepal. Search your field of interest and who knows the job of your dreams might be just a click away!"} />
                <meta property="og:image"
                    content={this.props.meta_img || "https://rojal.s3.ap-south-1.amazonaws.com/rojal/logo-small-png.png"} />
                <meta property="og:image:url"
                    content={this.props.meta_img || "https://rojal.s3.ap-south-1.amazonaws.com/rojal/logo-small-png.png"} />
                <meta property="og:image:alt"
                    content={'Rojgarisanjal Free job portal in nepal'} />
                <link rel="canonical" href="https://rojgarisanjal.com" />

                <meta property="fb:app_id" content="2234768930107771" />
                <meta property="description"
                    content={this.props.meta_desc ? this.props.meta_desc : this.props.title + " | Rojgari sanjal offers the widest range of jobs available in Nepal. Search your field of interest and who knows the job of your dreams might be just a click away!"} />

                <meta name="keywords"
                    content={this.props.keywords ?? 'Rojgarisanjal, Free job portal in nepal, Post free job in nepal, Employee in Nepal, Jobs in Nepal, Candidates in nepal, Job Vacancies in nepal'} />

                <meta property="og:site_name" content="Rojgari Sanjal" />
            </Head>

            <AppNav {...this.props} />
            <div className="main-page-content">
                {this.props.children}
            </div>
            <AppFooter authCheck={authCheck} footerExtraPadding={footerExtraPadding} />
            <AuthModel />
            <ApplyModal />
            <Feedback />
            <QuickSearch job={this.props.job} jobFilter={this.props.jobFilterInputChange} onClose={() => {
                this.props.generateStateUpdate(APP_GENERAL_STATE, { props: 'searchModal', value: false });
            }} show={this.props.app.searchModal} />
            <AddToFavourite />


        </React.Fragment>);
    }

    componentDidMount() {
        if (this.props.router.query && this.props.router.query.feed_back_modal === 'true') {
            this.props.feedbackGenerateState({ props: 'feedbackModal', value: true });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        job: state.job,
    }
};

export default connect(mapStateToProps, {
    generateStateUpdate,
    jobFilterInputChange,
    feedbackGenerateState
})(Layout);