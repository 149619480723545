import React from "react";
import {connect} from "react-redux";
import {feedbackGenerateState, feedbackInputChange, postFeedback} from "../../store/actions";
import {AppModal} from "./AppModal";
import {Button, Icon, Input, Select, Tooltip} from "antd";
import {feedType} from "../../utils/helpers";
import {redirect} from "../../utils/redirect";

const {Option} = Select;
const {TextArea} = Input;

const FeedbackComp = ({feedback, feedbackGenerateState, feedbackInputChange, postFeedback}) => {

    let isSelected = false;
    if (feedback.payload.rating) {
        isSelected = true;
    }

    return (<div>
        <div className="feed_backs_handler" onClick={() => {
            feedbackGenerateState({props: 'feedbackModal', value: true})
        }}>
            Feedback
        </div>


        <AppModal show={feedback.feedbackModal} width={550} onClose={() => {
            feedbackGenerateState({props: 'feedbackModal', value: false});
            setTimeout(() => {
                feedbackGenerateState({props: 'feedbackType', value: ''});
                feedbackInputChange({props: 'rating', value: 0});
                feedbackInputChange({props: 'feed_type', value: ''});
                feedbackInputChange({props: 'description', value: ''});
                feedbackInputChange({props: 'email', value: ''});
            }, 500);
        }}>
            <div className="feedback_container">
                {!feedback.feedbackType.length ? <div>
                    <br/>
                    <br/>
                    <div className="acc_label wide">
                        <p>Send Feedback</p>
                        <p> Responded within 24 hrs</p>
                    </div>
                    <div className="acc_selector wide">
                        <div onClick={() => {
                            feedbackGenerateState({props: 'feedbackType', value: 'SELECTED'});
                        }} className="personal select_box">
                            <Icon type="sound"/>
                            <p>Feedback</p>
                            <small style={{fontSize: 12, marginTop: 10}}>Want to provide feedback on website</small>
                        </div>
                        <div onClick={() => {
                            redirect('/contact');
                            feedbackGenerateState({props: 'feedbackModal', value: false});
                        }} className="company select_box" style={{marginLeft: 5}}>
                            <Icon type="message"/>
                            <p>Contact Us</p>
                            <small style={{fontSize: 12, marginTop: 10}}>Have a question would like to contact
                            </small>
                        </div>
                    </div>
                    <br/>
                    <br/>

                </div> : <div className={'feed_back_form'}>
                    <h1>Send Feedback</h1>

                    <div className="form-group">
                        <label htmlFor="">What do you think about us?</label>
                        <ul className={'emoticons_rate'}>
                            <li>
                                <Tooltip placement="top" title="Hate">
                                    <a href=""
                                       className={(feedback.payload.rating === 1 ? 'active ' : '') + (isSelected ? ' selected' : '')}
                                       onClick={(e) => {
                                           e.preventDefault();
                                           feedbackInputChange({props: 'rating', value: 1});
                                       }}>😡</a>
                                </Tooltip>
                            </li>
                            <li><Tooltip placement="top" title="Not Fun">
                                <a href=""
                                   className={(feedback.payload.rating === 2 ? 'active' : '') + (isSelected ? ' selected' : '')}
                                   onClick={(e) => {
                                       e.preventDefault();
                                       feedbackInputChange({props: 'rating', value: 2});
                                   }}>😔</a></Tooltip></li>
                            <li><Tooltip placement="top" title="Neutral" onClick={(e) => {
                                e.preventDefault();
                            }}><a
                                className={(feedback.payload.rating === 3 ? 'active' : '') + (isSelected ? ' selected' : '')}
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    feedbackInputChange({props: 'rating', value: 3});
                                }}>😶</a></Tooltip></li>
                            <li><Tooltip placement="top" title="Fun">
                                <a className={(feedback.payload.rating === 4 ? 'active' : '') + (isSelected ? ' selected' : '')}
                                   href=""
                                   onClick={(e) => {
                                       e.preventDefault();
                                       feedbackInputChange({props: 'rating', value: 4});
                                   }}>😁</a></Tooltip></li>
                            <li><Tooltip placement="top" title="Fantastic">
                                <a className={(feedback.payload.rating === 5 ? 'active' : '') + (isSelected ? ' selected' : '')}
                                   href=""
                                   onClick={(e) => {
                                       e.preventDefault();
                                       feedbackInputChange({props: 'rating', value: 5});
                                   }}>😍</a></Tooltip></li>
                        </ul>
                    </div>
                    {feedback.payload.rating > 0 ? <div className="form-group">
                        <label htmlFor="i_have">I have a</label>
                        <Select
                            id={'i_have'}
                            showSearch
                            style={{width: '100%'}}
                            placeholder="Choose a subject"
                            optionFilterProp="children"
                            onChange={(value) => {
                                feedbackInputChange({props: 'feed_type', value});
                            }}
                            onFocus={() => {
                            }}
                            onBlur={() => {
                            }}
                            onSearch={() => {
                            }}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            <Option value="SUGGESTION">Suggestion</Option>
                            <Option value="COMPLIMENT">Compliment</Option>
                            <Option value="BUG">Bug(Technical Error)</Option>
                            <Option value="ASK">Ask</Option>
                            <Option value="COMPLAINT">Complaint</Option>
                        </Select>
                    </div> : null}

                    {feedback.payload.feed_type ? <div className="form-group">
                        <label htmlFor="i_have">{feedType(feedback.payload.feed_type)}</label>
                        <TextArea rows={4} onChange={(e) => {
                            feedbackInputChange({props: 'description', value: e.target.value})
                        }}/>
                    </div> : null}

                    {feedback.payload.feed_type === 'ASK' || feedback.payload.feed_type === 'COMPLAINT' ?
                        <div className="form-group">
                            <label htmlFor="i_have">Email (optional)</label>
                            <Input placeholder="ram@example.com" onKeyUp={(e) => {
                                feedbackInputChange({
                                    props: 'email', value: e.target.value
                                })
                            }}/>
                            <label className={'mt-2 hint'}>Enter your email if you would like to receive a response
                                from us.</label>
                        </div> : null}

                    {feedback.payload.feed_type ? <Button className={'mt-0'} loading={feedback.saving}
                                                          disabled={!feedback.payload.feed_type.length || !feedback.payload.description.length}
                                                          onClick={() => {
                                                              postFeedback();
                                                          }} type={'primary'}>Send Feedback</Button> : null}
                </div>}

            </div>

            <br/>
            <br/>
            <div className={'mt-3'}>
                <small style={{fontSize: 11}}>
                    <Icon type="api"/> Powered by
                    <a href="http://grafiastech.com" rel="noreferrer" target={'_blank'}>&nbsp;
                        Grafias Technologies Pvt. LTD.
                    </a>
                </small>
            </div>
        </AppModal>

    </div>);
};


const mapStateToProps = (state) => {
    return {
        feedback: state.feedback
    }
};

const Feedback = connect(mapStateToProps, {
    feedbackGenerateState,
    feedbackInputChange,
    postFeedback
})(FeedbackComp);

export {Feedback};